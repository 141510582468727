import React, { useState, useContext, useEffect } from 'react';
import { EthereumClient, modalConnectors, walletConnectProvider } from '@web3modal/ethereum'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { mainnet } from 'wagmi/chains'
import { Web3Modal } from '@web3modal/react'

// 1. Get projectID at https://cloud.walletconnect.com
const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID || ''

// 2. Configure wagmi client
const chains = [ mainnet ]
const { provider } = configureChains(chains, [walletConnectProvider({ projectId })])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: 'web3Modal', chains }),
  provider
})

// 3. Configure modal ethereum client
export const ethereumClient = new EthereumClient(wagmiClient, chains)

export interface IWagmiState {
  account: string,
}

// export const WagmiContext = React.createContext({} as IWagmiState);
// export const useFlowWallet = () => useContext(WagmiContext);

const WagmiProvider = ({ children }) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true)
  }, [])

  if(!ready) return <></>

  return (
    <>
      <WagmiConfig client={wagmiClient}>
          {children}
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
}

export default WagmiProvider
