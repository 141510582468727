import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import * as Boxes from './styles';
import { mainMenu } from '../../config/menus';

export default function MainMenu({ insideSidebar, menu }) {
  const { t } = useTranslation('common');
  const router = useRouter();
  const currentPath = router.asPath;
  const currentMenu = menu || mainMenu;

  return (
    <Boxes.Menu insideSidebar={insideSidebar}>
      {currentMenu.map(item => (
        <Boxes.MenuItem key={item.title} isActive={item.href === currentPath}>
          <Link href={item.href}>
            <a target={item.target || '_self' }>{t(`menu.${item.title}`)}</a>
          </Link>
        </Boxes.MenuItem>
      ))}
    </Boxes.Menu>
  );
}

MainMenu.propTypes = {
  insideSidebar: PropTypes.bool,
};
