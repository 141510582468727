import CheckboxStyle from './Inputs/Checkbox'
import SelectStyle from './Inputs/Select'
import InputStyle from './Inputs/Input'
import TooltipStyle from './tooltip'
import { createGlobalStyle } from 'styled-components';
import { colorPallet, fontFamily } from '../components/styles/variables';
import '@fontsource/russo-one';
import '@fontsource/open-sans';
import '@fontsource/quicksand';
import "antd/dist/antd.css";

const GlobalStyle = createGlobalStyle`
  html > body {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 16px;
    vertical-align: baseline;
    font-family: ${fontFamily.content};
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow-x: hidden;
    background-color: ${colorPallet.pirateBlue};

    &:before{
      content: " ";
      display: block;
      color: white;
      z-index: 0;
      position: absolute;
      width: 100vw;
      height: 200vh;
      top: -100vh;
      left: 0;
      background: ${colorPallet.pirateBlue};
      background: radial-gradient(circle, rgba(15,19,52,1) -50%, rgba(8,10,23,1) 50%);
    }

    >#__next{
      min-height: 100%;
    }
    
    div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      vertical-align: baseline;
    }
    // HTML5 display-role reset for older browsers
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
      display: block;
    }
    body {
      line-height: 1;
    }
    ol, ul {
      list-style: none;
    }
    blockquote, q {
      quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
      content: '';
      content: none;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    h1, h2, h3, h4, h5, h6 {
      font-family: ${fontFamily.headings};
      font-weight: bold;
    }
    a:hover{
      color: ${colorPallet.pink}
    }
  }
`;

const InjectGlobalStyles = () => {
  return (
    <>
      <GlobalStyle />
      <CheckboxStyle />   
      <TooltipStyle />  
      <SelectStyle /> 
      <InputStyle /> 
    </>
  )
}

export default InjectGlobalStyles;
