import { createGlobalStyle } from 'styled-components';
import * as vars from '../../components/styles/variables';

const InputStyle = createGlobalStyle`
  html > body .ant-input-wrapper.ant-input-group{
    .ant-input-group-addon{
      color: black;
      font-weight: bold;
      background-color: ${vars.colors.darkPink};
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      padding-left: 15px;
    }
  }

  html > body .ant-input{
    background-color: ${vars.colorPallet.pirateBlue};
    box-shadow: none;
    color: ${vars.colors.inputPink};
    padding: 8px 15px;
    border: 2px solid ${vars.colorPallet.piratePink};
    border-radius: 25px;
    outline: 0;
    font-size: ${vars.fontSizes.tagline};
    line-height: 100%;

    &:focus, &:hover, &.ant-input-focused{
      border-color: ${vars.colorPallet.piratePink};
      box-shadow: none;
    }
  }
`;

export default InputStyle;