import { useState } from 'react';
import Link from 'next/link';
import BrandIcon from '../global/BrandIcon';
import MetamaskConnectHandler from '../global/metamask/connectHandler';
import ProfileMenu from '../../features/profile/profileMenu';
import { useProfile } from '../../features/providers/profile/index';
import { Container } from '../styles/blocks';
import Icon from '../global/BrandIcon';
import * as Boxes from '../styles/blocks';
import * as MenuBoxes from './styles';
import MainMenu from './MainMenu';
import SocialMenu from './SocialMenu';
import Sidebar from './Sidebar';
import LocaleToggle from './LocaleToggle';

export default function Header() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { profile } = useProfile();
    
  return (
    <>
      <MenuBoxes.Header>
        <Container>
          <MenuBoxes.FlexNavbar>
            {/* Favicon + Menu */}
            <Boxes.Flex ai="center">
              <Link href="/" passHref>
                <MenuBoxes.LogoLink>
                  <Icon name="skull" fontSize="40px" />
                </MenuBoxes.LogoLink>
              </Link>
              <Boxes.LgUpBlock>
                <MainMenu toggleMode={false} />
              </Boxes.LgUpBlock>
            </Boxes.Flex>

            {/* Mobile: Social */}
            <Boxes.LgDownBlock>
              <SocialMenu />
            </Boxes.LgDownBlock>

            {/* Desktop Social + toggle */}
            <Boxes.Flex ai="center">
              <Boxes.LgUpBlock>
                <Boxes.Flex ai="center" jc="center">
                  <SocialMenu />
                  <LocaleToggle />
                  
                  <Boxes.Block paddingL="16px">
                    <ProfileMenu profile={profile} />
                    {!profile && <MetamaskConnectHandler />}
                  </Boxes.Block>
                  
                </Boxes.Flex>
              </Boxes.LgUpBlock>

              <Boxes.LgDownBlock>
                <MenuBoxes.ToggleLink onClick={() => setToggleMenu(true)}>
                  <BrandIcon name="bars"/>
                </MenuBoxes.ToggleLink>
              </Boxes.LgDownBlock>
            </Boxes.Flex>
          </MenuBoxes.FlexNavbar>
        </Container>
      </MenuBoxes.Header>

      {/* Mobile sidebar */}
      <Sidebar {...{ toggleMenu, setToggleMenu }} />
    </>
  );
}
