export const colorPallet = {
  pirateBlue: '#080a17',
  piratePink: '#FFCEE1',
  piratePurple: '#A64DFF',
  pink: '#F01166',
  pinkDown: '#FEC9DC',
  gray: '#b9c1dc',
  grayDown: '#282931',
  menu: '#363C52',
  blue: '#221537',
  greenUp: '#B9C1DC',
  pageBg: '#0b0f28',
  purple: '#221537',
};

export const colors = {
  white: '#ffffff',
  gray: '#b9c1dc',
  darkGray: '#585D71',
  alternativeBlue: '#221537',
  lightBlue: '#1f2bf3',
  pirateBlue: '#080a17',
  inputPink: '#FFCEE1',
  lightPink: '#ffcfe2',
  darkPink: '#cd9caf',
  offPink: '#66535a',
  pink: '#760933',
  purple: '#2b0183',
  lightPurple: '#d3b9ff',
  fuchsia: '#f01166',
  darkFuchsia: '#760933',
  yellow: '#E8A945',
  limon: '#c8eb57',
};

// TODO: LUIYIT - UNIFY BOTH SIZES
export const appConfig = {
  menuHeight: '80px',
  menuHeightMd: '70px',
  footerHeight: '166px',
};

export const elementSizes = {
  menu: 80,
  footer: 166,
};

export const fontFamily = {
  headings: 'Russo One',
  content: 'Quicksand',
  alternative: 'Open Sans',
  fallback: 'sans-serif',
};
export const fontSizes = {
  xExtended: '80px',
  extended: '60px',
  xLarge: '48px',
  large: '40px',
  medium: '36px',
  mediumTitle: '30px',
  semiMediumTitle: '28px',
  cardTitle: '18px',
  subtitle: '24px',
  semiMedium: '24px',
  buttons: '19px',
  tagline: '19px',
  semiNormal: '17px',
  mediumButtons: '16px',
  sectionTitle: '16px',
  mediumText: '16px',
  news: '15px',
  normal: '14px',
  small: '12px',
  tiny: '10px',
  xTiny: '5px',
};

export const lineHeight = {
  small: '12px',
  medium: '30px',
  tagline: '25px',
  normal: '17px',
  titleDesktop: '45px',
  contentDesktop: '24px',
};

export const regularHeaderSizes = {
  large: '48px',
  primary: '26px',
  medium: '24px',
  normal: '20px',
  small: '16px',
};

export const headerSizes = {
  primary: '100px',
  secondary: '80px',
  large: '64px',
  medium: '50px',
  normal: '40px',
  semiNormal: '38px',
  small: '24px',
};

export const headerlineHeight = {
  normal: '90%',
};

export const layoutConfig = {
  backgroundColor: '#F6F6F6',
};

export const breakPoints = {
  small: 576,
  medium: 768,
  large: 992,
  xLarge: 1200,
  gutters: 15,
};

export const size = {
  xs: '374px',
  sm: '420px',
  md: '768px',
  mobileLayout: '992px', // Remove it later, when we define the breakpoints across the app.
  scLg: '1200px',
  lg: '1274px',
  xl: '1440px',
};

export const device = {
  xs: `(max-width: ${size.xs})`,
  sm: `(min-width: ${size.xs})`,
  md: `(min-width: ${size.md})`,
  mobileLayout: `(min-width: ${size.mobileLayout})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
};

export const zIndexs = {
  modals: 0,
  menu: 0,
  behindPage: 800,
  page: 900,
  overPage: 1000,
};

export const boxShadows = {
  initial: '0px 4px 12px rgba(0, 0, 0, 0.1)',
};

export const fontWeights = {
  regular: '400',
  bold: '700',
  extraBold: '800',
  black: '900',
};
