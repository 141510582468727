import dynamic from 'next/dynamic';
import { useEffect } from 'react'
const Layout = dynamic(() => import('./layoutComponent'));
import styled from 'styled-components';
import { useAccounts } from '../features/providers/account/AccountsProvider';

const FullScreen = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`

const AutoConnectPage = () => {
  const { connect } = useAccounts();
  useEffect(() => {
    connect();
  }, [connect]);

  return (
    <Layout hideFooter hideJoinUs>
      <FullScreen>
        CONNECTING METAMASK...
      </FullScreen>
    </Layout>
  )
}

export default AutoConnectPage
