import React, {useEffect, useState} from 'react';
import { toast } from 'react-toastify';
import { useAccounts } from '../../../features/providers/account/AccountsProvider';
import Button from '../button';
import { useWeb3Modal, Web3Button } from '@web3modal/react';

// https://lightrun.com/answers/walletconnect-web3modal-issues-getting-the-web3modal-to-open-up

const ctaStyles = {
  fontWeight: 600,
  letterSpacing: '3px',
  fontSize: '14px',
  padding: '12px 15px',
  bRadius: '8px',
  lineHeight: '100%',
  whiteSpace: 'nowrap'
};

/**
 * connect receives 
 */
function ConnectMetamaskWallet({ beforeConnect }) {
  const accounts = useAccounts();

  const connect = async () => {
    if(beforeConnect) beforeConnect();
    accounts.connect();
  };
  
  return (
    <>
      {accounts.connected && (
        <Button schema="transparentPink" styles={ctaStyles} type="button" onClick={accounts.disconnect} useQuicksand bold>
          DISCONNECT WALLET
        </Button>
      )}
      {!accounts.connected && (
        <Button schema="transparentPink" styles={ctaStyles} type="button" onClick={connect} useQuicksand bold>
          CONNECT WALLET
        </Button>
      )}
    </>
  );
}

export default ConnectMetamaskWallet;
