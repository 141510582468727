import React from 'react'
import PropTypes from 'prop-types';
import loading from '../../public/icons/last_version/wheel.png';
import styled from 'styled-components';
import { Block } from '../styles/blocks'
const AnimatedImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${({size})=> size}px;
  height: ${({size})=> size}px;
  margin: -${({size}) => parseInt(size/2)}px 0 0 -${({size}) => parseInt(size/2)}px;
  
  ${({animate}) => animate && "-webkit-animation: spin 4s linear infinite;"}
  ${({animate}) => animate && "-moz-animation: spin 4s linear infinite;"}
  ${({animate}) => animate && "animation: spin 4s linear infinite;"}

  @-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
  }
  @-webkit-keyframes spin { 
      100% { -webkit-transform: rotate(360deg); } 
  }
  @keyframes spin { 
      100% { 
          -webkit-transform: rotate(360deg); 
          transform:rotate(360deg); 
      } 
  }
`

const Loading = ({size, animate, ...props}) => {
  return (
    <Block position="relative" width={`${size}px`} height={`${size}px`} {...props} >
      <AnimatedImg src={loading.src} alt="wheel" {...{size, animate}}/>
    </Block>
  )
}

Loading.propTYpes = {
  size: PropTypes.number,
  animate: PropTypes.bool,
}

Loading.defaultProps = {
  size: 144,
  animate: true
}

export default Loading
