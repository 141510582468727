import React from 'react';
import PropTypes from 'prop-types';
import * as Boxes from '../../styles/blocks';

const BaseButton = (props) => {
  const { children, styles, value, ...rest } = props;

  return (
    <Boxes.Block as="button" type="button" {...rest} {...styles}>
      {value || children}
    </Boxes.Block>
  );
}

BaseButton.propTypes = {
  children: PropTypes.any,
  value: PropTypes.string,
  styles: PropTypes.object,
  onClick: PropTypes.func,
};



export default BaseButton
