import { useState, useEffect } from 'react'
import { profile as menu } from '../../config/menus';
import Avatar from './avatar';
import { useProfile } from '../../features/providers/profile/index';
import * as Boxes from '../../components/styles/blocks';
import * as vars from '../../components/styles/variables';
import Link from 'next/link';
import Paragraph from '../../components/global/text/Paragraph';
import Button from '../../components/global/button';
import styled from 'styled-components';
import { useAccounts } from '../providers/account/AccountsProvider';
import { useRouter } from 'next/router';

const MenuContainer = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  background-color: ${vars.colorPallet.menu};
  padding: 2px;
  border-radius: 10px;
  margin-top: 18px;

  &:before{
    content: "";
    display:block;
    position: absolute;
    right: 14px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 12px 8px;
    border-color: transparent transparent ${vars.colorPallet.menu} transparent;
  }
`;

const profileMenu = () => {
  const [open, setOpen] = useState(false)
  const { profile } = useProfile();
  const accounts = useAccounts();
  const router = useRouter();

  const closeMenu = (e) => {
    const { className } = e.target;
    if(typeof className?.includes !== 'function') return;

    if (className?.includes("profile-menu-trigger")) return;

    setOpen(false);
  }

  useEffect(() => {
    document.addEventListener("click", closeMenu);
    return () => {
      document.removeEventListener("click", closeMenu);
    }
  }, [])

  const onItemClick = item => {
    if (item.key === 'disconnect') {
      accounts.disconnect();
      router.push('/');
    }
  }

  const ctaStyles = {
    fontWeight: 700,
    letterSpacing: '3px',
    fontSize: '10px',
    padding: '8px 15px',
    bRadius: '5px',
    lineHeight: '100%',
    whiteSpace: 'nowrap'
  };

  const totalItems = menu.length;

  return (
    <Boxes.Block position="relative" >
      {profile && (
        <Boxes.Block onClick={() => setOpen(!open)} cursor="pointer" height="40px">
          <Avatar profile={profile} noLink className="profile-menu-trigger" />
        </Boxes.Block>
      )}

      {open && (
        <MenuContainer >
          {menu.map((item, index) => {
            if (item.href) return (
              <Boxes.Block key={item.key}>
                <Boxes.Block align="center" padding="10px">
                  <Link href={item.href}>
                    <a>
                      <Paragraph as="span" display="block"  color={vars.colorPallet.piratePink} fontFamily={vars.fontFamily.headings} uppercase letterSpacing="3px" lineHeight='100%' whiteSpace="nowrap">
                        {item.title}
                      </Paragraph>
                    </a>
                  </Link>
                </Boxes.Block>
                {index < totalItems && (
                  <Boxes.Block height="2px" bColor={vars.colorPallet.pirateBlue} />
                )}
              </Boxes.Block>
            )

            return (
              <Boxes.Block padding="10px" key={item.key}>
                <Button schema="transparentPink" bRadius={"5px"} style={ctaStyles} useQuicksand onClick={() => onItemClick(item)} >{item.title}</Button>
              </Boxes.Block>
            )
          })}
        </MenuContainer>
      )}
    </Boxes.Block>
  )
}

export default profileMenu
