import b58 from 'base58-string';
const getNFTSlug = (tokenId) => {
  const baseSlug = `A.${process.env.NEXT_PUBLIC_PIRATES_DEPLOYER.split("0x").at(-1)}.PiratesOfTheMetaverse.NFT:`;
  return b58.encode(`${baseSlug}${tokenId}`);
};

export const mainMenu = [
  {
    title: 'Gallery',
    href: '/gallery',
  },
  {
    title: 'Marketplace',
    href: 'https://marketplace.piratesnft.io',
    target: '_blank',
  },
  {
    title: 'Faq',
    href: '/faq',
  },
  {
    title: 'Teaser',
    href: '/teaser',
  },
  {
    title: 'Store',
    href: 'https://w3w.ai/main/potm',
    target: '_blank',
  },
];

export const subMenu = [
  {
    title: 'All Pirates',
    key: 'all',
    href: '/gallery',
  },
  {
    title: 'My Pirates',
    key: 'my',
    href: '/my-pirates',
  },
  {
    title: 'Crew Strength',
    key: 'crew',
    href: '/crew-strength',
    shouldRender: () => (false)
  },
]

export const profile = [
  {
    title: 'My Pirates',
    icon: '',
    key: 'my-pirates',
    href: '/my-pirates',
  },
  {
    title: 'My Drip ID',
    icon: '',
    key: 'my-drip-id',
    href: '/account',
  },
  {
    title: 'Disconnect Wallet',
    key: 'disconnect',
  },
]

export const holderEthSubMenu = [
  {
    title: 'Pirates',
    key: 'eth_pirates',
    href: '/holder',
  },
]

export const holderFlowSubMenu = [
  {
    title: 'Pirates',
    key: 'flow_pirates',
    href: '/holder',
  },
]

export const socialMenu = [
  {
    title: '',
    icon: 'marketplace',
    href: 'https://marketplace.piratesnft.io/collections/0xe75113d4a417c2d33c67fb127b419e5f47c5d62c',
  },
  {
    icon: 'gaia',
    href: 'https://ongaia.com/pirates-of-the-metaverse/',
    disabled: true,
  },
  {
    title: '',
    icon: 'twitter',
    href: 'https://twitter.com/PiratesMeta',
  },
  {
    title: '',
    icon: 'discord',
    href: 'https://discord.gg/tSNQgrV9z6',
  },
  {
    title: '',
    icon: 'instagram-v2',
    href: 'https://www.instagram.com/piratesmeta/',
    disabled: false,
  },
];

export const marketplaces = [
  {
    title: '',
    icon: 'marketplace',
    href: 'https://marketplace.piratesnft.io/collections/0xe75113d4a417c2d33c67fb127b419e5f47c5d62c',
    getTokenUrl: tokenId => `https://marketplace.piratesnft.io/collections/0xe75113d4a417c2d33c67fb127b419e5f47c5d62c/tokens/${tokenId}`,
    blockchain: "eth"
  },
  {
    title: '',
    icon: 'gaia',
    href: 'https://ongaia.com/pirates-of-the-metaverse/',
    getTokenUrl: tokenId => `https://ongaia.com/pirates-of-the-metaverse/nft/${getNFTSlug(tokenId)}`,
    blockchain: "flow"
  },
];

export const accountMenu = [
  {
    title: 'Drip ID',
    key: 'drip-id',
  },
  {
    title: 'Wallets',
    key: 'wallets',
  },
  {
    title: 'Prestige',
    key: 'prestige',
    subTitle: '(BETA)',
  },
]
