/**
 * Config options
 * 
 * onSessionFail ==> [homeRedirect, autoConnect]
 * waitForSession ==> Show loading screen before the session is resolved
 * injectCurrentUser ==> Pass currentUser attr as a prop to the component
 * 
 */
const pagesAuthConfig = {
  '/account': {
    waitForSession: true,
    injectCurrentUser: true,
    onSessionFail: 'homeRedirect',
  },
  '/claim': {
    waitForSession: true,
    injectCurrentUser: true,
    onSessionFail: 'homeRedirect',
  },
  '/my-pirates': {
    waitForSession: true,
    injectCurrentUser: true,
  },
  '/my-factions': {
    waitForSession: true,
    injectCurrentUser: true,
  },
}

const defaultConfig = { waitForSession: false, injectCurrentUser: false }

export default function(route){
  const { pathname } = route;
  return pagesAuthConfig[pathname] || defaultConfig;
}