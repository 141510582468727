import { createAuthProvider } from 'react-token-auth';
import { getStorage } from '../../../helpers/window_storages_helper';

export type ISession = { 
  accessToken: string; 
  client: string; 
  uid: string;
};

const storage = getStorage();
const { useAuth, authFetch, login, logout } = createAuthProvider<ISession>({
    getAccessToken: session => session.accessToken,
    storage,
});

export { useAuth, authFetch, login, logout };
