import "../public/icons.css";
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import {
  ContractProvider,
  useAccounts,
} from 'really-easy-web3';
import AccountsProvider from '../features/providers/account/AccountsProvider';
import WagmiProvider from '../features/providers/wagmi/WagmiProvider';
import FlowProvider from '../features/providers/flow/FlowProvider';
import AuthProvider from '../features/providers/auth/index';
import * as ga from '../helpers/google';
import GlobalStyle from '../styles/global';
import ProfileProvider from '../features/providers/profile/index';
import injectAuth from '../features/providers/profile/injectAuth';
import pageAuthConfig from '../config/auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/navigation/Header';
import { contract } from '../config/contract';
import { getProvider } from '../config/provider';
import Logo from '../public/pirates-logo.jpg';
import { useProvider } from 'wagmi';
import { isMobile } from 'react-device-detect';

global.claimEnabled = process.env.NEXT_PUBLIC_CLAIM_ENABLED === "true";

function Website({ Component: Page, pageProps }) {
  const accounts = useAccounts();
  const rpcProvider = useProvider({
    chainId: parseInt(process.env.NEXT_PUBLIC_INT_CHAIN_ID),
  });

  const providerParams = useMemo(() => { 
    if(isMobile) return [null, rpcProvider]
    return [accounts.default];
  }, [accounts.default, rpcProvider]);
  
  let provider = getProvider(...providerParams);
  
  return (
    <>
      <GlobalStyle />

      <ContractProvider contracts={[contract]} provider={provider} deps={[accounts.default]}>
        <ProfileProvider>
          <Header />
          <Page {...pageProps} />
        </ProfileProvider>
      </ContractProvider>
    </>
  );
}

Website.propTypes = {
  Component: PropTypes.any.isRequired,
  pageProps: PropTypes.object.isRequired,
};

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

function AppRoot({ Component, pageProps, cadenceScripts }) {
  const [ready, setReady] = useState(false)
  const router = useRouter();
  const ComponentWrapper = injectAuth(Component, pageAuthConfig(router));
    
  useEffect(() => {
    setReady(true)
  }, [])

  useEffect(() => {
    const handleRouteChange = url => {
      ga.pageview(url);
    };

    handleRouteChange(router.pathname);

    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, router.pathname]);

  const flowConfig = {
    "discovery.wallet": process.env.NEXT_PUBLIC_DISCOVERY_WALLET,
    "discovery.authn.include": [process.env.NEXT_PUBLIC_DAPPER_OPT_IN_WALLETS],
    'accessNode.api': process.env.NEXT_PUBLIC_ACCESS_NODE_API,
    'app.detail.title': "PiratesOfTheMetaverse",
    'app.detail.icon': typeof window !== 'undefined' && `${window?.location?.origin}${Logo.src}`
  };

  if(process.env.NEXT_PUBLIC_WALLET_DISCOVERY_METHOD)
    flowConfig['discovery.wallet.method'] = process.env.NEXT_PUBLIC_WALLET_DISCOVERY_METHOD;
      
  return (
    <>
      <WagmiProvider>
        <FlowProvider config={flowConfig}>
          <AccountsProvider
            autoConnect
            simulateReconnect
          >
            <AuthProvider>
              <ToastContainer style={{ top: "90px", zIndex: 9999999 }}/>
              <Website {...{ Component: ComponentWrapper, pageProps }} />
            </AuthProvider>
          </AccountsProvider>
        </FlowProvider>
      </WagmiProvider>
    </>
  );
}

AppRoot.propTypes = {
  Component: PropTypes.any.isRequired,
  pageProps: PropTypes.object.isRequired,
};

export default appWithTranslation(AppRoot);
