import dynamic from 'next/dynamic';
const Layout = dynamic(() => import('./layoutComponent'));
import styled from 'styled-components';

const FullScreen = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`
import Paragraph from './global/text/Paragraph';
import * as Boxes from './styles/blocks';
import * as vars from './styles/variables';
import Loading from './global/loading';

const LoadingPage = () => {
  return (
    <Layout hideFooter hideJoinUs>
      <FullScreen>
        <Boxes.Block maxWidth="320px" align="center">
          <Paragraph bold fontSize={vars.fontSizes.subtitle} marginB="15px">LOADING</Paragraph>
          <Loading margin="0 auto" size={100} />
        </Boxes.Block>
      </FullScreen>
    </Layout>
  )
}

export default LoadingPage
