import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { colors, colorPallet } from '../styles/variables';

const LocaleLink = styled.a`
  font-family: 'quicksand', 'sans-serif';
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
  text-decoration: none;
  color: ${colorPallet.piratePink};
  padding: 0 10px;
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: none;
  }

  &:hover {
    color: ${colors.lightPurple};
  }
`;

export default function LocaleToggle() {
  const { t } = useTranslation('common');
  const router = useRouter();
  const currentPath = router.asPath;

  return (
    <Link href={currentPath} locale={router.locale === 'en' ? 'es' : 'en'} passHref>
      <LocaleLink>{t('change-locale')}</LocaleLink>
    </Link>
  );
}
