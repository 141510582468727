import { Result } from "antd";
import CryptoJS from "crypto-js";

export const generateCredentials = address => {
  if (!address) return {};
  return {
    email: `${address}@piratesnft.io`.toLowerCase(),
    password: CryptoJS.MD5(`${address.toLowerCase()}_${process.env.NEXT_PUBLIC_MD5_KEY.toLowerCase()}`).toString(),
  };
};

// MD5 C#
// https://ideone.com/0655cz

// EJEMPLO 1
// https://jsfiddle.net/w3q2p1h4/15/
// const name = "JORGE";
// const email = `${name.toLowerCase()}@piratesnft.io`;
// const SECRET = 'a48dc0d831e941b796e384b50edca248';
// const input = `${email.toLowerCase()}_${SECRET.toLowerCase()}`;

// const hashMD5 = CryptoJS.MD5(input);
// /* const hashSHA256 = CryptoJS.SHA256(input); */


// $('#1').text("JS MD5: " + hashMD5);
// /* $('#2').text("JS SHA256: " + hashSHA256); */
// Result
// C# MD5: 85551bd072ed8930af1cd091d41279cf
// JS MD5: 85551bd072ed8930af1cd091d41279cf

// EJEMPLO 2
// https://jsfiddle.net/w3q2p1h4/12/

// const name = "JORGE";
// const email = `${name.toLowerCase()}@piratesnft.io`;
// const SECRET = 'a48dc0d831e941b796e384b50edca248';
// const input = `${email}_${SECRET}`;

// const hashMD5 = CryptoJS.MD5(input);
// const hashSHA256 = CryptoJS.SHA256(input);


// $('#1').text("JS MD5: " + hashMD5);
// $('#2').text("JS SHA256: " + hashSHA256);

// Result
// C# MD5: 85551bd072ed8930af1cd091d41279cf
// JS MD5: 85551bd072ed8930af1cd091d41279cf
// JS SHA256: 6091d5acd4b4b794a15aa2e00ffa65c1edbef1fe58eb66bd8c940c3223658a57
