import { createGlobalStyle } from 'styled-components';
import * as vars from '../../components/styles/variables';

const selectStyle = createGlobalStyle`
  html > body .pirates-select{
    color: ${vars.colorPallet.pinkDown};
    line-height: 100%;
    position: relative;
    z-index: 100;
    min-width: 120px;
    cursor: pointer;
    
    margin: 0;
    text-align: center;
    height: 45px;

    .pirates-wrapper{
      position: absolute;
      top: 0;
      width: 100%;
      border: 2px solid ${vars.colorPallet.pinkDown};
      background-color: ${vars.colorPallet.pirateBlue};
      border-radius: 25px;
      
      .label{
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        display: inline-block;
        width: 90%;
        font-size: ${vars.fontSizes.tagline};
        line-height: 21px;
      }

      .pirates-select-value{
        padding: 9px 12px;
        
        &.name, &.selected {
          display: flex;
          align-items: center;
          justify-content: center;
          i{
            pointer-events: none;
            margin-left: 10px;
          }
        }
        &.name {
          justify-content: center;
        }

        &.name{
          opacity: 0.6;
        }

        &.item{
          padding: 10px;
        }

        &[disabled]{
          opacity: 0.6;
          cursor: initial;
        }
      }
  
      .pirates-select-dropdown{
        visibility: none;
      }
    }

    &.open{
      .pirates-wrapper{
        .pirates-select-value{
        }
      }
    }

  }
`;

export default selectStyle;