/**
 * Setting up the correct RPC provider for the current environment.
 * 
 * @returns IProvider interface
 */
export const getProvider = (address, rpcProvider) => {
  
  if(address) return { name: "WEB3" }

  if(rpcProvider){
    return {
      rpc: rpcProvider,
      name: "CUSTOM_RPC",
    }
  }

  if(process.env.NEXT_PUBLIC_IS_LOCAL) {
    return {
      name: "LOCAL_RPC",
      host: process.env.NEXT_PUBLIC_RPC_HOST,
      port: process.env.NEXT_PUBLIC_RPC_PORT,
    }
  }

  if(process.env.NEXT_PUBLIC_IS_STAGING || process.env.NEXT_PUBLIC_IS_PROD) {
    return {
      name: "INFURA_RPC",
      token: process.env.NEXT_PUBLIC_INFURA_RPC_TOKEN,
      network: process.env.NEXT_PUBLIC_NETWORK,
    }
  }

  return { name: "WEB3" }
}