import { createGlobalStyle } from 'styled-components';
import * as vars from '../components/styles/variables';

const tooltipStyle = createGlobalStyle`
  html > body .ant-tooltip-content{
    font-family: ${vars.fontFamily.headings};
    
    .ant-tooltip-inner{
      background-color: #222125;
      color: ${vars.colorPallet.piratePink};
      font-weight: 400;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 20px;
    }
    .ant-tooltip-arrow{
      .ant-tooltip-arrow-content{
        --antd-arrow-background-color: #222125;
      }
    }
  }
`;

export default tooltipStyle;