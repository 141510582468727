/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Identicon from 'identicon.js';
import Link from 'next/link';
import * as Boxes from '../../components/styles/blocks';
import { colorPallet } from '../../components/styles/variables';
import Img from '../../components/global/image/BaseImage';

function Avatar({ profile, size = 40, border, noLink, className, enableClick = true }) {

  const data =
    profile?.metamaskAddress &&
    new Identicon(profile.metamaskAddress, {
      foreground: [255, 255, 255, 255],
      background: [63, 33, 143, 255],
      margin: 0.2,
      size: size || 40,
      format: 'png',
    }).toString();

  const styles = {
    width: `${size}px`,
    height: `${size}px`,
    alt: "",
    border: border && `2px solid ${colorPallet.piratePink}`,
    bRadius: "50%",
    src: profile?.avatarUrl || `data:image/png;base64,${data}`,
  };

  if(noLink) return (
    <Img className={className} {...styles}/>
  )

  return (
    <>
      {data && (
        <Boxes.Block className={className}>
          {enableClick ? (
            <Link href="/account">
              <a style={{display: "flex"}}>
                <Img {...styles}/>
              </a>
            </Link>
          ) : (
            <Img {...styles} />
          )}
        </Boxes.Block>
      )}
    </>
  );
}

export default Avatar;
