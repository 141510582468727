/**
 * DOCS
 * https://wagmi.sh/react/hooks/useAccount
 */
import React, { useState, useContext, useEffect, useCallback } from 'react';
import * as storage from './storage'
import getInitialState, { IState } from './config';
import { useAccount, useDisconnect } from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';

interface IAcctContext{
  default: string;
  connect: Function,
  personalSign: Function,
  disconnect: Function,
  accounts: string[],
  loading: Boolean
  connected: Boolean
  connecting: Boolean
}

export const AccountContext = React.createContext({} as IAcctContext);
export const useAccounts = () => useContext(AccountContext);

interface IAccountProps {
  children: any;
  connectOnLoad: boolean,
  autoConnect: boolean,
  simulateReconnect: boolean,
}

const AccountsProvider = ({ children }: IAccountProps) => {
  
  const [accounts, setAccounts] = useState<string[]>([]);
  const [state, setState] = useState<IState>(getInitialState());

  const wagmiAccount = useAccount()
  const wagmiDisconnect = useDisconnect();

  const modal = useWeb3Modal();
  const { open } = modal
  
  useEffect(() => {
    if(wagmiAccount.address){
      setAccounts([wagmiAccount.address]);
      setAccountsState([wagmiAccount.address]);
    } else if(accounts.length > 0) {
      setAccounts([]);
      setState(getInitialState());
    }
  }, [wagmiAccount.address, accounts.length]);

  const setAccountsState = (accounts:string[] = []) => {
    setState(getInitialState(accounts))
  }
  
  const disconnect = (setFakeDisconnect: boolean = true) => {
    const { disconnect } = wagmiDisconnect;
    storage.setFake(setFakeDisconnect);
    disconnect();

    setAccounts([]);
    setState(getInitialState());
  }

  const connect = async () => {
    open();
  }

  // TODO: Create component to sign
  const personalSign = async () => {
    throw new Error('PersonalSign not implemented')
  }
  
  const contextValue: IAcctContext = {
    default: wagmiAccount.address || '',
    connect,
    disconnect,
    personalSign,
    accounts,
    ...state,
    loading: wagmiAccount.isConnecting,
    connected: wagmiAccount.isConnected,
    connecting: wagmiAccount.isConnecting || wagmiAccount.isReconnecting,
  };

  return (
    <AccountContext.Provider value={contextValue} >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountsProvider;
