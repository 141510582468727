import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Block } from '../../styles/blocks';
import { breakPoints as bp } from '../../styles/variables';

const TextTag = styled(Block)`
  position: ${({ position }) => position || 'relative'};

  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`}
  ${({ inline }) => inline && `display: inline;`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  ${({ fontStyle }) => fontStyle && `font-style: ${fontStyle};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  ${({ bold }) => bold && `font-weight: 600;`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
  ${({ lineBreak }) => lineBreak && `line-break: ${lineBreak};`}
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ transform }) => transform && `text-transform: ${transform};`}
  ${({ decoration }) => decoration && `text-decoration: ${decoration};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ align }) => align && `text-align: ${align};`}
  ${({ vAlign }) => vAlign && `vertical-align: ${vAlign};`}
  ${({ wordBreak }) => wordBreak && `word-break: ${wordBreak};`}
  ${({ textOverflow }) => textOverflow && `text-overflow: ${textOverflow};`}
  ${({ display }) => display && `display: ${display};`}

  @media (max-width: ${bp.large}px) {
    ${({ alignMd }) => alignMd && `text-align: ${alignMd};`}
  }

  @media (max-width: 992px) {
    ${({ mediumFontSize }) => mediumFontSize && `font-size: ${mediumFontSize};`}
  }

  @media (max-width: 768px) {
    ${({ smallFontSize }) => smallFontSize && `font-size: ${smallFontSize};`}
  }
`;

function Paragraph(props) {
  // eslint-disable-next-line prefer-const
  let { children, ...rest } = props;

  const autoProps = {};
  if (props.uppercase) autoProps.transform = 'uppercase ';
  if (props.underline) autoProps.decoration = 'underline ';
  if (props.center) autoProps.textAlign = 'center';

  return (
    <TextTag {...rest} {...autoProps}>
      {children}
    </TextTag>
  );
}

Paragraph.propTypes = {
  children: PropTypes.any,
  as: PropTypes.string,
  capitalize: PropTypes.bool,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  fontStyle: PropTypes.string,
  mediumFontSize: PropTypes.string,
  smallFontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  letterSpacing: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  uppercase: PropTypes.bool,
  underline: PropTypes.bool,
  inline: PropTypes.bool,
  center: PropTypes.bool,
  wordBreak: PropTypes.string,
};

export default Paragraph;
