import React, { useState, useContext, useEffect, useMemo } from 'react';
export const FlowContext = React.createContext({} as IFlowState);
export const useFlowWallet = () => useContext(FlowContext);
import * as fcl from "@onflow/fcl";
import axios from 'axios';

interface IFlowUser {
  addr: string,
  cid: string,
  f_type: string,
  expiresAt: number,
  loggedIn: boolean,
}

const defaultFlowUser : IFlowUser = {
  addr: '',
  cid: '',
  f_type: '',
  expiresAt: 0,
  loggedIn: false,
};

export interface IFlowState {
  user: IFlowUser | undefined,
  loading: boolean,
  setupAccount: Function,
  tokensOfOwner: Function,
  connect: Function,
  disconnect: Function,
}

const FlowProvider = ({ children, config }) => {
  const [user, setUser] = useState<IFlowUser>(defaultFlowUser);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fcl.config(config);
    fcl.currentUser.subscribe(onUserConnect);
  }, [config]);

  // TODO! if the user is not connected, loading is always true
  useEffect(() => {
    if((user?.f_type || '').toLowerCase() === "user") {
      setLoading(false);
    }
  }, [user]);

  const onUserConnect = async (user) => {
    setUser(user);
  }
  const connect = async () => {
    const data = await fcl.authenticate();
    return data;
  }

  const disconnect = async () => {
    await fcl.unauthenticate();
  }

  const setupAccount = async (callback) => {
    try {
      // I can create an script to validate if the account was already configured
      // TO avoid ask for initialization always
      const userSnapshot = await fcl.currentUser().snapshot();
      if(!userSnapshot.loggedIn) return;

      var { data: cadenceScript } = await axios.get("/cadence/setup_account.cdc");
      var { data: prodCadenceScript } = await axios.get("/cadence/production_setup_account.cdc");
      const script = process.env.NEXT_PUBLIC_USE_PROD_SCRIPT === "1" ? prodCadenceScript : cadenceScript;

      const dapperAuthz = fcl.authz;
      const transactionId = await fcl.mutate({
        cadence: script,
        proposer: fcl.authz,
        authorizations: [dapperAuthz, fcl.authz],
        payer: fcl.authz,
        limit: 9999,
      }).catch(() => {
        if(callback) callback(); 
      })

      const result = await fcl.tx(transactionId).onceSealed()
      if(result.errorMessage){
        // TODO! Send to sentry
        return {
          success: false,
          error: result.errorMessage,
          transactionId
        }
      }      
      return { success: true, transactionId };

    } catch (_) {}
    
    return { success: false };
  }

  const tokensOfOwner = async (address) => {
    if ((!user.addr && !address) || !global.claimEnabled) return [];

    const localAddress = address || user.addr;
    try {
      var { data: collectionIds } = await axios.get("/cadence/get_collection_ids.cdc");
      var { data: prodCollectionIds } = await axios.get("/cadence/production_get_collection_ids.cdc");
      const script = process.env.NEXT_PUBLIC_USE_PROD_SCRIPT === "1" ? prodCollectionIds : collectionIds;

      const tokensIds: string[] = await fcl.query({
        cadence: script,
        args: (arg, t) => [arg(localAddress, t.Address)]
      });

      return tokensIds.map(token => parseInt(token));

    } catch (_) {}
    
    return [];
  }

  const contextValue: IFlowState = {
    user,
    loading,
    setupAccount,
    tokensOfOwner,
    connect,
    disconnect,
  }

  return (
    <FlowContext.Provider value={contextValue} >
      {children}
    </FlowContext.Provider>
  );
}

export default FlowProvider
