import { motion } from 'framer-motion';
import styled, { createGlobalStyle } from 'styled-components';
import Container from 'react-bootstrap/Container';
import { colors, colorPallet, breakPoints } from '../styles/variables';
import * as Boxes from '../styles/blocks';

export const Header = styled.header`
  width: 100vw;
  position: relative;
  position: fixed;
  top: 0;
  z-index: 999;
  background: ${colorPallet.pirateBlue};
  height: 76px;

  .container {
    height: 76px;
  }
`;

export const FlexNavbar = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`;

export const GlobalToggleStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const MotionContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: rgba(0, 9, 20, 0.82);

  .sidebar-content {
    padding-left: 40px;
    background-color: ${colors.pirateBlue};
    position: relative;
    z-index: 1;
    width: 85%;
    height: 100%;
    margin-left: 15%;
  }
`;

export const Menu = styled.ul`
  display: ${({ insideSidebar }) =>
    (insideSidebar && 'block') || 'flex'};

  /* margin-top: ${({ insideSidebar }) => (insideSidebar && '80px') || '0'}; */

  li {
    display: ${({ insideSidebar }) =>
      (insideSidebar && 'block') || 'inline-block'};
    font-size: 20px;
    line-height: 30px;
    padding: ${({ insideSidebar }) =>
      (insideSidebar && '5px 0') || '0 10px'};
    font-family: 'quicksand', 'sans-serif';
  }
`;

export const MenuItem = styled.li`
  a,
  a:hover,
  a:active {
    font-weight: bold;
    text-transform: uppercase;
    color: ${({ isActive }) => (isActive && colors.fuchsia) || colors.darkPink};
    text-decoration: none;
  }

  a:hover {
    color: ${({ isActive }) =>
      (isActive && colors.fuchsia) || colors.lightPurple};
  }
`;

export const SocialMenuItem = styled(MenuItem)`
  a {
    font-size: 25px;
  }
`;

// TODO: move it from here
export const MarketPlaces = styled(Boxes.Flex)`
  @media (min-width: ${breakPoints.medium}px) {
    margin-left: 10px;
  }
  
  a {
    color: ${colorPallet.piratePurple};
    display: inline-block;
    margin: 1px 5px;

    @media (max-width: ${breakPoints.medium}px) {
      color: white;
    }
    
    i{
      font-size: 40px;
    }
    
    :hover{
      color: ${colorPallet.piratePurple};
    }
  }
`;

export const LogoLink = styled.a`
  display: inline-block;
  font-size: 40px;
  line-height: 30px;
  padding: 0 10px 0 0;
  text-decoration: none;
  cursor: pointer;

  i {
    color: ${colors.darkPink};
  }
`;

export const ToggleLink = styled(Boxes.Block)`
  display: inline-block;
  padding: 0 10px;
  color: ${colors.darkPink};
  svg {
    cursor: pointer;
  }
`;
export const CloseToggleLink = styled(ToggleLink)`
  color: ${colors.lightBlue};
  i {
    cursor: pointer;
  }
`;
