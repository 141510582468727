
import axios from 'axios';
const rootPath = process.env.NEXT_PUBLIC_API_URL;
import {useAuth, authFetch, login, logout, ISession} from './tokenProvider';
import RequestService from '../../../services/RequestService';
import { getStorage } from '../../../helpers/window_storages_helper';

const defaultSession = {
  useAuth: () => ([false, {}]),
  signIn: async () => {}
}

export const useSession = () => {

  if (typeof window === "undefined") {
    return defaultSession
  }
  
  const signUp = async (body) => {
    try {
      const response = await axios.post(
        `${rootPath}/auth`,
        body,
      );
      const { headers, data } = response;
      setSession(headers);
      return { success: true, ...data}

    } catch (error) {
      return {success: false, error};
    }
  };

  const signIn = async (body) => {
    try {
      const response = await axios.post(
        `${rootPath}/auth/sign_in.json`,
        body,
      );
      const { headers, data } = response;
      setSession(headers);
      return { success: true, ...data}
    } catch (error) {
      return error;
    }
  }

  const signOut = async (localOnly = false) => {
    const storage = getStorage();
    const authData = storage.getItem('REACT_TOKEN_AUTH_KEY');

    // No token on storage
    if(!authData || localOnly) {
      logout();
      return { success: true };
    }
    
    try {
      const response = await RequestService.delete(`${rootPath}/auth/sign_out`, {}, {});
      const { data } = response;
      logout();
      return { success: true, ...data}
    } catch (error) {
      return error;
    }
  }

  const setSession = (headers) => {
    const accessToken = headers['access-token'];
    if (!accessToken) return;

    const session : ISession = {
      accessToken,
      client: headers['client'],
      uid: headers['uid']
    }
    
    login(session);
  }
  
  return {
    signIn,
    signUp,
    useAuth,
    setSession,
    logout: signOut,
  }
}