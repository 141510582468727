import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import * as vars from '../styles/variables';
import * as Boxes from '../styles/blocks';
import SwitchLanguage from './LocaleToggle';
import * as MenuBoxes from './styles';
import MainMenu from './MainMenu';
import Icon from '../global/BrandIcon';
import { profile as profileMenu, mainMenu } from '../../config/menus';
import { useProfile } from '../../features/providers/profile/index';
import ConnectHandler from '../../components/global/metamask/connectHandler';
import Avatar from '../../features/profile/avatar';

const menuVariant = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: 'easeIn',
    },
  },
};

export default function Sidebar({ toggleMenu, setToggleMenu }) {
  const { profile } = useProfile();

  return (
    <AnimatePresence exitBeforeEnter>
      {toggleMenu && (
        <MenuBoxes.MotionContainer
          variants={menuVariant}
          initial="hidden"
          animate="visible"
          exit="exit"
          onClick={({ target }) => {
            if (target.nodeName === 'A') setToggleMenu(false);
          }}
        >
          <MenuBoxes.GlobalToggleStyle />
          <div className="sidebar-content">
            <Boxes.Flex ai="center" padding="10px" jc="space-between">
              <SwitchLanguage />
              <MenuBoxes.CloseToggleLink align="right">
                <Icon
                  onClick={() => setToggleMenu(false)}
                  name="cancel"
                  fontSize="30px"
                  color={vars.colorPallet.piratePink}
                />
              </MenuBoxes.CloseToggleLink>
            </Boxes.Flex>
            <Boxes.Block marginT="50px">
              {/* Profile Menu */}
              { profile && (
                <>
                  <Avatar size={80} profile={profile} noLink className="profile-menu-trigger" />
                  <MainMenu menu={profileMenu.filter(({href}) => !!href)} insideSidebar />
                  <Boxes.Block margin="10px 0 20px 0">
                    <ConnectHandler />
                  </Boxes.Block>                    
                  <Boxes.Block as="hr" marginR="40px" opacity="0.51" bColor={vars.colorPallet.piratePink}/>
                </>
              )}

              {/* Connect metamask */}
              { !profile && (
                <Boxes.Block marginB="20px">
                  <ConnectHandler beforeConnect={() => setToggleMenu(false)}/>
                </Boxes.Block>
              )}

              {/* Main Menu */}
              <MainMenu setToggleMenu={setToggleMenu} insideSidebar />
            </Boxes.Block>
          </div>
        </MenuBoxes.MotionContainer>
      )}
    </AnimatePresence>
  );
}

Sidebar.propTypes = {
  toggleMenu: PropTypes.bool.isRequired,
  setToggleMenu: PropTypes.func.isRequired,
};
