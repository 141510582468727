/* eslint-disable no-console */
import axios from 'axios';
import qs from 'query-string';
import changeCase from 'change-object-case';
import { getStorage } from '../helpers/window_storages_helper';

const storage = getStorage();

const keyMatch = {
  accessToken: 'access-token',
};

const request = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

request.interceptors.request.use(config => {
  const authData = storage.getItem('REACT_TOKEN_AUTH_KEY');
  if(!authData) return config;
  
  try {
    const headers = JSON.parse(authData);
    Object.keys(headers).forEach(key => {
      // eslint-disable-next-line no-param-reassign
      config.headers[keyMatch[key] || key] = headers[key];
    });
  } catch (error) {
    console.error('Unable to set request headers; ', authData);
    console.error(error);
  }

  return config;
});

/**
 * Simple service to make different king of request
 * including authenticity token if exist
 *
 * @export
 * @class RequestService
 */
export default class RequestService {

  /**
   * 
   * @param {object} data Object to serialize
   * @returns FormData
   */
  static createFormData(data){
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if(!data[key]) return;
      formData.append(changeCase.snakeCase(key), data[key]);
    });

    return formData;
  }

  /**
   * Get request
   *
   * @static
   * @param {string} url
   * @param {object} params
   *
   * @returns Request promise
   * @memberof RequestService
   */
  static get(url, params = null, config = {}) {
    const stringified = RequestService.stringifiedParams(params || {});
    return RequestService.makeRequest(
      request.get,
      `${url}?${stringified}`,
      config
    );
  }

  /**
   * Put request
   *
   * @static
   * @param {string} url
   * @param {object} params
   *
   * @returns Request promise
   * @memberof RequestService
   */
  static put(url, params, config) {
    return RequestService.makeRequest(
      request.put,
      url,
      RequestService.transformParams(params || {}),
      config,
    );
  }

  /**
   * Patch request
   *
   * @static
   * @param {string} url
   * @param {object} params
   *
   * @returns Request promise
   * @memberof RequestService
   */
  static patch(url, params, config) {
    return RequestService.makeRequest(
      request.patch,
      url,
      RequestService.transformParams(params),
      config,
    );
  }

  /**
   * Post request
   *
   * @static
   * @param {string} url
   * @param {object} params
   *
   * @returns Request promise
   * @memberof RequestService
   */
  static post(url, params, config) {
    return RequestService.makeRequest(
      request.post,
      url,
      RequestService.transformParams(params),
      config,
    );
  }

  /**
   * Delete request
   *
   * @static
   * @param {string} url
   * @param {object} params
   *
   * @returns Request promise
   * @memberof RequestService
   */
  static delete(url, params, config) {
    const stringified = qs.stringify(
      RequestService.transformParams(params || {}),
    );

    return RequestService.makeRequest(
      request.delete,
      `${url}?${stringified}`,
      config,
    );
  }

  /**
   * Simple wrapper to make request
   *
   * @static
   * @param {function} call axios method call
   * @param {string} url
   * @param {object} params
   *
   * @returns Request promise
   * @memberof RequestService
   */
  static makeRequest = (call, url, requestParams, config) =>
    new Promise((resolve, reject) => {
      const [path, query] = url.split("?");
      let reqPath = "";
      if(!query) reqPath = url;
      else reqPath = `${path.toLowerCase()}?${query}`;
      
      call(reqPath, requestParams, config)
        .then(response => {
          resolve([response.data, response.headers, null]);
        })
        .catch(error => {
          const { response } = error;
          /**
           * DEV NOTE
           * When the request is cancelled, we get an error with cancel object
           * Cancel => { message }
           * In this case we don't want to reject the request
           */
          if (response) resolve([null, null, response.data]);
          else {
            if(error?.message !== "Component unmounted"){
              
            }
          }
        });
    });

  /**
   * Change  camel case params to underscore
   *
   * @static
   * @param {object} params
   *
   * @returns underscore params
   * @memberof RequestService
   */
  static transformParams = body => {
    if(body instanceof FormData) return body;
    
    return changeCase.snakeKeys(body, {
      recursive: true,
      arrayRecursive: true,
    });
  }

  static stringifiedParams = (params = {}) => {
    const paramGroups = Object.keys(params).reduce(
      (current, key) => {
        let { atomic, array } = current;
        if (Array.isArray(params[key]))
          array = { ...array, [key]: params[key] };
        else atomic = { ...atomic, [key]: params[key] };

        return { atomic, array };
      },
      { atomic: {}, array: {} },
    );

    const atomicParams = qs.stringify(
      RequestService.transformParams(paramGroups.atomic || {}),
    );
    const arrayParams = RequestService.stringifyArrayParams(paramGroups.array);

    if (!arrayParams) return atomicParams;
    return [atomicParams, arrayParams].join('&');
  };

  static stringifyArrayParams = (params = {}) => {
    const stringified = Object.keys(params).map(key =>
      params[key].map(value => `${key}[]=${value}`).join('&'),
    );
    return stringified.join('&');
  };

  /**
   * Transform into single array
   *
   * @static
   * @param {object} response
   *
   * @returns array of elements' atributes
   * @memberof RequestService
   */
  static extractItemList = response => response.data.map(el => el.attributes);
}
