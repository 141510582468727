import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Block } from '../../styles/blocks';

const BaseImage = styled(Block)`
  ${({ bRadius }) => bRadius && `border-radius: ${bRadius};`}
  ${({ flexShrink }) =>
    flexShrink !== undefined && `flex-shrink: ${flexShrink};`}
  ${({ fill }) => fill && `fill: ${fill};`}
  
  path, .vector {
    ${({ fill }) => fill && `fill: ${fill};`}
  }
  
  &.error{
    ${({ hideOnError }) => hideOnError && `display: none;`}
  }
`;

function Image(props){
  
  const onError =({currentTarget}) => { 
    currentTarget.classList.add('error')
  } 
  const onLoad =({currentTarget}) => { 
    currentTarget.classList.remove('error') 
  }

  return <BaseImage as="img" {...props} {...{onError, onLoad}} />;
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  bRadius: PropTypes.string,
};

export default Image;
