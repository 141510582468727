/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link';
import * as Boxes from './styles';
import Icon from '../global/BrandIcon';
import { socialMenu } from '../../config/menus';

export default function SocialMenu() {
  return (
    <Boxes.Menu>
      {socialMenu.map(item => {
        if (item.disabled) return null;

        return (
          <Boxes.SocialMenuItem key={item.icon}>
            <Link href={item.href}>
              <a>
                <Icon name={item.icon} fontSize="20px" />
              </a>
            </Link>
          </Boxes.SocialMenuItem>
        );
      })}
    </Boxes.Menu>
  );
}
