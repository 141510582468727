import React from 'react';
import Base from './base';
import { colorPallet, fontFamily } from '../../styles/variables';
import pSBC from 'shade-blend-color';

const baseBackgroundColor = {
  pink: {
    bColor: colorPallet.pink,
    color: colorPallet.pinkDown,
  },
  gray: {
    bColor: colorPallet.gray,
    color: colorPallet.blue,
  },
  softPink: {
    bColor: colorPallet.piratePink,
    color: colorPallet.purple,
  },
  transparentPink: {
    bColor: 'transparent',
    border: `2px solid ${colorPallet.piratePink}`,
    color: colorPallet.piratePink,
  },
  transparentGrey: {
    bColor: 'transparent',
    border: `2px solid ${colorPallet.gray}`,
    color: colorPallet.gray,
  }
}
const schemaGenerator = (opts) => {
  const { bRadius, schema, shadow, useQuicksand } = opts;
  const schemaStyle = baseBackgroundColor[schema];
  const shadowStyle = shadow && {
    boxShadow: `-0.30em 0.30em ${pSBC(-0.4, schemaStyle.bColor)}`
  } || {}

  return {
    bRadius: bRadius || '25px',
    padding: '13px 23px',
    color: 'white',
    fontFamily: useQuicksand ? fontFamily.content : fontFamily.headings,
    transform: 'uppercase',
    fontSize: '20px',
    border: 'none',
    ...schemaStyle,
    ...shadowStyle
  }
}

const Button = (props) => {
  const { schema, children, value, styles, ...rest } = props
  const baseStyles = { ...schemaGenerator(props), ...styles };

  return (
    <Base {...rest} styles={baseStyles} >
      {value || children}
    </Base>
  )
}

export default Button
