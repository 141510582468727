import { createGlobalStyle } from 'styled-components';
import * as vars from '../../components/styles/variables';

const checkboxStyle = createGlobalStyle`
  html > body .ant-checkbox-wrapper{
    position: relative;
    width: 100%;

    /* Label hover */
    &:hover .ant-checkbox-inner{
      border-color: ${vars.colorPallet.piratePink};
    }  

    /* Input focus */
    .ant-checkbox-input:focus + .ant-checkbox-inner{
      border-color: ${vars.colorPallet.piratePink};
    }

    /* Checked hover */
    .ant-checkbox-checked{
      &:hover{
        .ant-checkbox-inner{
          border-color: ${vars.colorPallet.piratePink};
        }
      }

      .ant-checkbox-inner{
        border-radius: 5px;
        background-color: ${vars.colorPallet.piratePink};

        &:after{ display: none; }
      }
    }

    /* Simple hover */
    .ant-checkbox{
      &:hover{
        .ant-checkbox-inner{
          border-color: ${vars.colorPallet.piratePink};
        }
      }

      &:after{
        display: none;
      }
    }

    .ant-checkbox-inner{
      width: 20px;
      height: 20px;
      border: 2px solid ${vars.colorPallet.piratePink};
      border-radius: 5px;
      background: transparent;
    }

    > span:not(.ant-checkbox){
      width: 100%;
      display: block;
      line-height: 100%;
    }

    .label{
      display: inline-block;
      width: 75%;
      white-space: nowrap;
      /* overflow: hidden !important; */
      text-overflow: ellipsis;
    } 

    .percent{
      position: absolute;
      font-weight: bold;
      right: 0;
      opacity: 0.75;
    }
  }
`;

export default checkboxStyle;