import { useProfile } from './index';
import React from 'react';
import LoadingPage from '../../../components/LoadingPage';
import AutoConnectPage from '../../../components/AutoConnectPage';
import { useRouter } from 'next/router';

/**
 * This injector works fine, but produces a small flash on load when change loading component by final one
 * To prevent it, the layout should be outside of each page.
 * TODO: move layout to app.js
 * 
 * @param {function} Component react component
 * @param {object} authConfig current page's auth configuration
 * 
 * @returns React component
 */
export default function injectAuth(Component, authConfig)
{  
  if (!authConfig.injectCurrentUser)
    return (props) => (<Component {...props} />)
  
  let wasRedirected = false;

  return function(props){
    const router = useRouter();
    const authProps = { authConfig }
    const currentUser = useProfile();
    const { ethereumAccount } = currentUser;
        
    if (authConfig.injectCurrentUser)
      authProps['currentUser'] = currentUser
    
    if (authConfig.waitForSession && (currentUser.loadingProfile || ethereumAccount.loading)) {
      return (
        <LoadingPage />
      )
    }

    if(!currentUser.loadingProfile && !currentUser.profile){
      
      if(authConfig.onSessionFail === 'homeRedirect') {
        /**
         * Dev note:
         * Default page (in this case Homepage) shouldn't have auth configuration
         * So, the initial return apply
         */
                
        if(!wasRedirected)
        {          
          // Flash message ==> you need to connect your metamask wallet
          wasRedirected = true;
          router.push('/');
        }

        return <LoadingPage />
      }
      
      const { connected } = currentUser.ethereumAccount;
      if(!connected && authConfig.onSessionFail === 'autoConnect') {
        return (
          <AutoConnectPage />
        )
      }
    }

    return <Component {...props} currentUser={currentUser} />
  }
}