import React from 'react';
import PropTypes from 'prop-types';
import * as Boxes from '../styles/blocks';
import styled from 'styled-components';

const IconBox = styled(Boxes.Block)`
  display: flex;
`

function BrandIcon({ name, ...rest }) {
  const { fontSize, className, ...props } = rest
  return <IconBox as="i" className={`pnft-${name} ${className || ""}`} {...props} iconFontSize={fontSize} fontSize={fontSize} />;
}

BrandIcon.propTypes = { name: PropTypes.string.isRequired };
BrandIcon.defaultProps = { fontSize: '16px' };

export default BrandIcon;
