import React, { useState, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSession } from './useSession';
import { ISession} from './tokenProvider';

import { useAccounts } from '../../../features/providers/account/AccountsProvider';
import { generateCredentials } from './secret_generator';
import { toast } from 'react-toastify';
import { getLocalStorage } from '../../../helpers/window_storages_helper';

const localStorage = getLocalStorage();
export const AuthContext = React.createContext({} as IAuth);
export const useAuth = () => useContext(AuthContext);

export interface IAuth {
  signIn: Function,
  signUp: Function,
  logged: boolean,
  loadingSession: boolean,
  session: ISession,
  setSession: Function,
  logout: Function,
}

const AuthProvider = ({ children }) => {
  const { signIn, signUp, useAuth, setSession, logout }: any = useSession();
  const [loadingSession, setLoading] = useState(true)
  const [logged, session] = useAuth();
  const accounts = useAccounts();
  
  /*
   * DEV NOTE!
   * When the user connects the wallet, we want to make a new login request
   * So, when the app inits, we need to remove the previous token
   */
  useEffect(() => {
    if (!accounts.connected && !accounts.connecting) {
      setLoading(false);
      // Remove token locally - We don't want to remove all token for this account/user
      logout(true)
    }
  }, [accounts])

  const onConnect = async () => {
    localStorage.setItem('isNewAccount', 'false');
    setLoading(true);

    /**
     * Let's try to login first
     */
    const credentials = generateCredentials(accounts.default);
    const res = await signIn(credentials);
        
    /**
     * When login fail it's because the user is not already sign up.
     * We should try to do it
     */
    if(!res.success){
      const singUp = await signUp(credentials);

      if(!singUp.success) {
        await accounts.disconnect();
        console.error("onConnect login/signUp error: ", singUp)
        toast.info("Something is wrong. Connect your wallet again please.");
        window.location.reload();
      }else{
        localStorage.setItem('isNewAccount', 'true');
      }
    }
    
    setLoading(false);
  }

  const onDisconnect = async () => {
  }

  useEffect(() => {
    if (accounts.connected) onConnect();
    else onDisconnect();
  }, [accounts.connected])

  const contextValue: IAuth = {
    signIn, 
    signUp, 
    logged,
    session,
    setSession,
    logout,
    loadingSession,
  }

  return (
    <AuthContext.Provider value={contextValue} >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AuthProvider;
