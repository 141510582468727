import styled from 'styled-components';
import React from 'react';
import { breakPoints, breakPoints as bp, fontFamily as ff } from './variables';

export const shadows = {
  softTop: {
    boxShadow: '0px -2px 8px -2px rgba(0, 0, 0, 0.3)',
  },
  softBottom: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
};

export const positionProps = {
  zero: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  center: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  topCenterOverline: {
    top: '0',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  leftCenterHeader: {
    left: '0',
    top: '50%',
    transform: 'translate(30%, -50%)',
  },
  bottom: {
    bottom: '0',
  },
  bottomLeft: {
    left: '0',
    bottom: '0',
  },
  bottomRight: {
    right: '0',
    bottom: '0',
  },
  bottomCenter: {
    left: '50%',
    bottom: '0',
    transform: 'translate(-50%, 0)',
  },
  topLeft: {
    left: '0',
    top: '0',
  },
  topRight: {
    right: '0',
    top: '0',
  },
  topCenter: {
    left: '50%',
    top: '0',
    transform: 'translate(-50%, 0)',
  },
  fullWidthtopLeft: {
    left: '0',
    top: '0',
    width: '100%',
  },
  fullScreen: {
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
  },
  rightCenter: {
    right: '0',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
};

export const Block = styled.div`
  position: ${({ position }) => position || 'relative'};

  ${({ align }) => align && `text-align: ${align};`}
  ${({ vAlign }) => vAlign && `vertical-align: ${vAlign};`}
  ${({ event }) => event && `pointer-events: ${event};`}
  ${({ bRadius }) => bRadius && `border-radius: ${bRadius};`}
  ${({ bTLRadius }) => bTLRadius && `border-top-left-radius: ${bTLRadius};`}
  ${({ bBLRadius }) => bBLRadius && `border-bottom-left-radius: ${bBLRadius};`}
  ${({ bTRRadius }) => bTRRadius && `border-top-right-radius: ${bTRRadius};`}
  ${({ bBRRadius }) => bBRRadius && `border-bottom-right-radius: ${bBRRadius};`}
  ${({ bColor }) => bColor && `background-color: ${bColor};`}
  ${({ background }) => background && `background: ${background};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ display }) => display && `display: ${display};`}
  ${({ overflow }) => overflow && `overflow: ${overflow};`}
  ${({ overflowY }) => overflowY && `overflow-y: ${overflowY};`}
  ${({ transform }) => transform && `transform: ${transform};`}
  ${({ transition }) => transition && `transition: ${transition};`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow};`}
  ${({ right }) => right && `right: ${right};`}
  ${({ top }) => top && `top: ${top};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  
  // Border radius
  ${({ bRadius }) => bRadius && `border-radius: ${bRadius};`}
  ${({ bTLRadius }) => bTLRadius && `border-top-left-radius: ${bTLRadius};`}
  ${({ bTRRadius }) => bTRRadius && `border-top-right-radius: ${bTRRadius};`}
  ${({ bBLRadius }) => bBLRadius && `border-bottom-left-radius: ${bBLRadius};`}
  ${({ bBRRadius }) => bBRRadius && `border-bottom-right-radius: ${bBRRadius};`}

  // Width
  ${({ width }) => width && `width: ${width};`}
  ${({ wh }) => wh && `width: ${wh};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}

  // Height
  ${({ height }) => height && `height: ${height};`}
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}

  // Border
  ${({ border }) => border && `border: ${border};`}
  ${({ borderT }) => borderT && `border-top: ${borderT};`}
  ${({ borderR }) => borderR && `border-right: ${borderR};`}
  ${({ borderB }) => borderB && `border-bottom: ${borderB};`}
  ${({ borderL }) => borderL && `border-left: ${borderL};`}
  ${({ borderColor }) => borderColor && `border-color: ${borderColor};`}
  ${({ borderWidth }) => borderWidth && `border-width: ${borderWidth};`}
  ${({ borderStyle }) => borderStyle && `border-style: ${borderStyle};`}

  // Flex
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ fShrink }) => fShrink && `flex-shrink: ${fShrink};`}
  ${({ fGrow }) => fGrow && `flex-grow: ${fGrow};`}

  // Margin
  ${({ margin }) => margin && `margin: ${margin};`}

  ${({ marginX }) => marginX && `margin-left: ${marginX};`}
  ${({ marginX }) => marginX && `margin-right: ${marginX};`}
  ${({ marginY }) => marginY && `margin-top: ${marginY};`}
  ${({ marginY }) => marginY && `margin-bottom: ${marginY};`}

  ${({ marginR }) => marginR && `margin-right: ${marginR};`}
  ${({ marginL }) => marginL && `margin-left: ${marginL};`}
  ${({ marginT }) => marginT && `margin-top: ${marginT};`}
  ${({ marginB }) => marginB && `margin-bottom: ${marginB};`}

  // Padding
  ${({ padding }) => padding && `padding: ${padding};`}

  ${({ paddingX }) => paddingX && `padding-left: ${paddingX};`}
  ${({ paddingX }) => paddingX && `padding-right: ${paddingX};`}
  ${({ paddingY }) => paddingY && `padding-top: ${paddingY};`}
  ${({ paddingY }) => paddingY && `padding-bottom: ${paddingY};`}

  ${({ paddingR }) => paddingR && `padding-right: ${paddingR};`}
  ${({ paddingL }) => paddingL && `padding-left: ${paddingL};`}
  ${({ paddingT }) => paddingT && `padding-top: ${paddingT};`}
  ${({ paddingB }) => paddingB && `padding-bottom: ${paddingB};`}

  // Inherit purpose
  ${({ color }) => color && `color: ${color};`}
  ${({ fontFamily }) =>
    fontFamily && `font-family: ${fontFamily}, ${ff.fallback};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ transform }) => transform && `text-transform: ${transform};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  ${({ bold }) => bold && `font-weight: 600;`}
  
  @media(max-width: ${bp.xLarge}px) {
    ${({ heightXl }) => heightXl && `height: ${heightXl};`}
    ${({ widthXl }) => widthXl && `width: ${widthXl};`}
    ${({ paddingXlB }) => paddingXlB && `padding-bottom: ${paddingXlB};`}
  }

  // Flex
  ${({ ai }) => ai && `align-items: ${ai};`}
  ${({ jc }) => jc && `justify-content: ${jc};`}

  @media (max-width: ${bp.large}px) {
    ${({ alignMd }) => alignMd && `text-align: ${alignMd};`}

    ${({ heightMd }) => heightMd && `height: ${heightMd};`}
    
    // Width
    ${({ widthMd }) => widthMd && `width: ${widthMd};`}
    ${({ maxWidthMd }) => maxWidthMd && `max-width: ${maxWidthMd};`}
    ${({ minWidthMd }) => minWidthMd && `min-width: ${minWidthMd};`}

    ${({ heightMd }) => heightMd && `height: ${heightMd};`}

    ${({ displayMd }) => displayMd && `display: ${displayMd};`}
    ${({ paddingMd }) => paddingMd && `padding: ${paddingMd};`}

    ${({ bColorMd }) => bColorMd && `background-color: ${bColorMd};`}
    ${({ colorMd }) => colorMd && `color: ${colorMd};`}

    ${({ paddingMdX }) => paddingMdX && `padding-left: ${paddingMdX};`}
    ${({ paddingMdX }) => paddingMdX && `padding-right: ${paddingMdX};`}
    ${({ paddingMdY }) => paddingMdY && `padding-top: ${paddingMdY};`}
    ${({ paddingMdY }) => paddingMdY && `padding-bottom: ${paddingMdY};`}
    
    ${({ paddingMdB }) => paddingMdB && `padding-bottom: ${paddingMdB};`}
    ${({ paddingMdT }) => paddingMdT && `padding-top: ${paddingMdT};`}
    ${({ paddingMdR }) => paddingMdR && `padding-right: ${paddingMdR};`}
    ${({ paddingMdL }) => paddingMdL && `padding-left: ${paddingMdL};`}

    ${({ marginMd }) => marginMd && `margin: ${marginMd};`}
    ${({ marginMdX }) => marginMdX && `margin-left: ${marginMdX};`}
    ${({ marginMdX }) => marginMdX && `margin-right: ${marginMdX};`}
    ${({ marginMdY }) => marginMdY && `margin-top: ${marginMdY};`}
    ${({ marginMdY }) => marginMdY && `margin-bottom: ${marginMdY};`}
    
    ${({ marginMdB }) => marginMdB && `margin-bottom: ${marginMdB};`}
    ${({ marginMdT }) => marginMdT && `margin-top: ${marginMdT};`}
    ${({ marginMdR }) => marginMdR && `margin-right: ${marginMdR};`}
    ${({ marginMdL }) => marginMdL && `margin-left: ${marginMdL};`}

    ${({ positionMd }) => positionMd && `position: ${positionMd};`}

    ${({ fontSizeMd }) => fontSizeMd && `font-size: ${fontSizeMd};`}
  }

  @media (max-width: ${bp.medium}px) {
    ${({ paddingSm }) => paddingSm && `padding: ${paddingSm};`}
    ${({ displaySm }) => displaySm && `display: ${displaySm};`}
    ${({ widthSm }) => widthSm && `width: ${widthSm};`}
    ${({ maxWidthSm }) => maxWidthSm && `max-width: ${maxWidthSm};`}
    ${({ minWidthSm }) => minWidthSm && `min-width: ${minWidthSm};`}
    ${({ marginSmL }) => marginSmL && `margin-left: ${marginSmL};`}
    ${({ marginSmX }) => marginSmX && `margin-left: ${marginSmX};`}
    ${({ marginSmX }) => marginSmX && `margin-right: ${marginSmX};`}
    ${({ marginSmT }) => marginSmT && `margin-top: ${marginSmT};`}
  }

  @media (max-width: ${bp.small}px) {
    ${({ paddingXs }) => paddingXs && `padding: ${paddingXs};`}
    ${({ widthXs }) => widthXs && `width: ${widthXs};`}
    ${({ marginXsB }) => marginXsB && `margin-bottom: ${marginXsB};`}
    ${({ marginXsT }) => marginXsT && `margin-top: ${marginXsT};`}
    ${({ paddingXs }) => paddingXs && `padding: ${paddingXs};`}
  }
`;

export const ThemeBlock = styled(Block).attrs(
  ({ mode, color, colorForced, bColor, bColorForced }) => ({
    color:
      colorForced ||
      (mode === 'dark' && 'white') ||
      (mode === 'white' && 'black') ||
      color ||
      'black',
    bColor:
      bColorForced ||
      (mode === 'dark' && 'black') ||
      (mode === 'white' && 'white') ||
      bColor ||
      'white',
  }),
)``;

export const Container = styled(Block)`
  margin: 0 auto;
  ${({ noGutters }) => !noGutters && `padding-left: ${bp.gutters}px;`}
  ${({ noGutters }) => !noGutters && `padding-right: ${bp.gutters}px;`}

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;

export const LgUpContainer = styled(Block)`
  @media (min-width: ${bp.large + 1}px) {
    margin: 0 auto;
    ${({ noGutters }) => !noGutters && `padding-left: ${bp.gutters}px;`}
    ${({ noGutters }) => !noGutters && `padding-right: ${bp.gutters}px;`}
    
    max-width: 960px;
  }

  @media (min-width: ${bp.xLarge}px) {
    max-width: 1200px;
  }
`;

export const LgDownBlock = styled(Block)`
  @media (min-width: ${bp.large + 1}px) {
    display: none;
  }
`;

export const MdDownBlock = styled(Block)`
  @media (min-width: ${bp.medium + 1}px) {
    display: none;
  }
`;

export const LgUpBlock = styled(Block)`
  @media (max-width: ${bp.large}px) {
    display: none;
  }
`;

export const MdUpBlock = styled(Block)`
  @media (max-width: ${bp.medium}px) {
    display: none;
  }
`;

export const SmUpBlock = styled(Block)`
  @media (max-width: ${bp.small + 1}px) {
    display: none;
  }
`;

export const Flex = styled(Block)`
  display: ${({ display }) => display || 'flex'};
  ${({ wrap }) => wrap && `flex-wrap: ${wrap};`}
  ${({ gap }) => gap && `gap: ${gap};`}
  ${({ ai }) => ai && `align-items: ${ai};`}
  ${({ jc }) => jc && `justify-content: ${jc};`}
  ${({ fd }) => fd && `flex-direction: ${fd};`}
  ${({ js }) => js && `justify-self: ${js};`}
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  
  @media(max-width: ${bp.xLarge}px) {
    ${({ fdXl }) => fdXl && `flex-direction: ${fdXl};`}
  }

  @media (max-width: ${bp.large}px) {
    ${({ wrapMd }) => wrapMd && `flex-wrap: ${wrapMd};`}
    ${({ fdMd }) => fdMd && `flex-direction: ${fdMd};`}
    ${({ aiMd }) => aiMd && `align-items: ${aiMd};`}
    ${({ jcMd }) => jcMd && `justify-content: ${jcMd};`}
    ${({ fdMd }) => fdMd && `flex-direction: ${fdMd};`}
    ${({ wrapMd }) => wrapMd && `flex-wrap: ${wrapMd};`}
  }

  @media (max-width: ${bp.medium}px) {
    display: ${({ displayMd }) => displayMd || 'flex'};
    ${({ wrapSm }) => wrapSm && `flex-wrap: ${wrapSm};`}
    ${({ fdMdSm }) => fdMdSm && `flex-direction: ${fdMdSm};`}
  }

  @media (max-width: ${bp.small}px) {
    ${({ fdXs }) => fdXs && `flex-direction: ${fdXs};`}
  }
`;

export const AbsoluteBlock = styled(Block)`
  position: ${({ position }) => position || 'absolute'};
  ${({ left }) => left && `left: ${left};`}
  ${({ right }) => right && `right: ${right};`}
  ${({ top }) => top && `top: ${top};`}
  ${({ bottom }) => bottom && `bottom: ${bottom};`}
  ${({ transform }) => transform && `transform: ${transform};`}
  ${({ transition }) => transition && `transition: ${transition};`}
  @media(max-width: ${breakPoints.medium}px) {
    ${({ leftMd }) => leftMd && `left: ${leftMd};`}
    ${({ rightMd }) => rightMd && `right: ${rightMd};`}
    ${({ topMd }) => topMd && `top: ${topMd};`}
    ${({ bottomMd }) => bottomMd && `bottom: ${bottomMd};`}
  }

  @media (max-width: ${breakPoints.small}px) {
    ${({ leftSm }) => leftSm && `left: ${leftSm};`}
    ${({ rightSm }) => rightSm && `right: ${rightSm};`}
    ${({ topSm }) => topSm && `top: ${topSm};`}
    ${({ bottomSm }) => bottomSm && `bottom: ${bottomSm};`}
  }
`;

export function AbsoluteCentered(props) {
  return (
    <AbsoluteBlock
      margin="auto"
      top="50%"
      left="50%"
      transform="translateY(-50%) translateX(-50%)"
      {...props}
    />
  );
}

export const BackgroundImage = styled(Block)`
  background-image: ${({ src }) => `url(${src})`};
  opacity: ${({ opacity }) => opacity};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  -webkit-background-size: ${({ size }) => size || 'cover'};
  -moz-background-size: ${({ size }) => size || 'cover'};
  -o-background-size: ${({ size }) => size || 'cover'};
  background-size: ${({ size }) => size || 'cover'};
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  font-family: Lato;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 24px;
  }
`;
